var sGlobs = {
	search: { // ---------- search-form.js -------------
		query: '', // form serialized data
		data: [], // all result data
		pos: 12, // start pos for infinite loader
		page: 1, // current page
		mapActive: false, // true = load map
		mapExpand: false, // true = load map
		mapMax: 200, // total pages
		incrementby: 12, // inifinite loader increments
		total: 0, // total results
		totalPages: 0, // total pages
		infiniteLoad: false, // infiniteload status
		sort: "",
		postcodes: "",
		selectedItem: {},
	},
	back: { // cache all lazy loaded listing data, to populate when user navigtates back to search
		data: [],
		loadCache: false,

		isEnabled: typeof window.sessionStorage != "undefined", // session cache is available
		sessionkey_data: "backListingsData",
		sessionkey_dataQuery: "backListingsQuery",
		sessionkey_dataTotal: "backListingsTotal",
		sessionkey_dataPage: "backListingsData_page",
		sessionkey_dataTotalPages: "backListingsData_totalpages",
		sessionkey_loadEnabled: "backListingsData_enable",

		/* helpers */
		// set session -- run when loading new data
		set: function() {
			if (this.isEnabled) {

				window.sessionStorage[this.sessionkey_data] = JSON.stringify(this.data);
				window.sessionStorage[this.sessionkey_dataQuery] = sGlobs.search.query;
				window.sessionStorage[this.sessionkey_dataTotal] = sGlobs.search.total;
				window.sessionStorage[this.sessionkey_dataPage] = sGlobs.search.page;
				window.sessionStorage[this.sessionkey_dataTotalPages] = sGlobs.search.totalPages;
				window.sessionStorage[this.sessionkey_loadEnabled] = this.loadCache = false;
			}
		},
		// get session data when activated -- run during init
		get: function() {
			if (this.isEnabled && window.sessionStorage[this.sessionkey_loadEnabled] == "true") {

				try {
					this.data = JSON.parse(window.sessionStorage[this.sessionkey_data]) || [];
				}
				catch(err) {
					console.log(err.message);
				}

				this.loadCache = true;

				sGlobs.search.query = window.sessionStorage[this.sessionkey_dataQuery];
				sGlobs.search.total = parseInt(window.sessionStorage[this.sessionkey_dataTotal]);
				sGlobs.search.page = parseInt(window.sessionStorage[this.sessionkey_dataPage]);
				sGlobs.search.totalPages = parseInt(window.sessionStorage[this.sessionkey_dataTotalPages]);

				this.updateForm(sGlobs.search.query.split("&"))
			}
		},
		// repopulate search form
		updateForm: function(arr) {
			sGlobs.search.listing_property_type = [];
			sGlobs.search.listing_suburb_id = [];
			sGlobs.search["LISTING_PRICE_FROM"] = [];
			sGlobs.search["LISTING_PRICE_TO"] = [];

			var checker = setInterval(function() {

				if (!_status_gettingPropertyTypes) { // _status_gettingPropertyTypes: a quick hack to check if property types loaded
					init();
					clearInterval(checker);
				}

			}, 1000);

			function init() {

				var exclusions = [
					"authenticityToken",
					];

				var ajaxedselectors = [
					"listing_property_type",
					"listing_suburb_id",
					"LISTING_PRICE_FROM",
					"LISTING_PRICE_TO",
				]

				for (var i in arr) {
					var field = arr[i].split("=");
					var key = field[0],
						value = decodeURIComponent(field[1].replace(/\+/g, "%20"));

					if (exclusions.indexOf(key) != -1)
						continue;

					if (ajaxedselectors.indexOf(key) != -1) {
						sGlobs.search[key].push(value);
					}

				}

				for (var i in ajaxedselectors) {
					var key = ajaxedselectors[i];
					var $input = $("#search-form").find("[name='"+key+"']");

					if (sGlobs.search[key].length > 1 || $input.is("select")) {

						$input.find("option").each(function() {
							var _thisVal = decodeURIComponent($(this).val().replace(/\+/g, "%20"));

							if (sGlobs.search[key].indexOf(_thisVal) != -1)
								$(this).prop('selected', true);
						})

						$input.change();
					}

					else {

						if (sGlobs.search[key] && sGlobs.search[key][0])
							$input.val(sGlobs.search[key][0]);
					}

				}

			}

		},
		// activate -- run when event "Back" from a listing
		activate: function() {
			if (this.isEnabled)
				window.sessionStorage[this.sessionkey_loadEnabled] = true;
		},
		// deactivate -- after population of content
		deactivate: function() {
			if (this.isEnabled) {
				window.sessionStorage[this.sessionkey_loadEnabled] = this.loadCache = false;
			}
		},
		reset: function() {
			if (this.isEnabled) {

				window.sessionStorage[this.sessionkey_dataQuery] = "";
				window.sessionStorage[this.sessionkey_dataTotal] = 0;
				window.sessionStorage[this.sessionkey_dataPage] = 0;
				window.sessionStorage[this.sessionkey_dataTotalPages] = 0;
				window.sessionStorage[this.sessionkey_loadEnabled] = false;
			}
		}
	},
	document: getComputedStyle(document.documentElement),
	CSS: {} // store css vars here
};


$(function(){

	var isChildOffice = $('body').hasClass('isoffice');

	/* Cache CSS variables -- use func: switchBrand(obj) */
	sGlobs.CSS = {
		primary: {
			'--color-primary': sGlobs.document.getPropertyValue("--color-primary"),
			'--color-primary-lighten': sGlobs.document.getPropertyValue("--color-primary-lighten"),
			'--color-primary-lighten-more': sGlobs.document.getPropertyValue("--color-primary-lighten-more"),
		},
		corporate: {
			'--color-primary': sGlobs.document.getPropertyValue("--color-corporate"),
			'--color-primary-lighten': sGlobs.document.getPropertyValue("--color-corporate-lighten"),
			'--color-primary-lighten-more': sGlobs.document.getPropertyValue("--color-corporate-lighten-more")
		},
		sidecolWidth: sGlobs.document.getPropertyValue("--sidecol-width"),
	}

	/* Initialise any images */
	loadImgs();

	/* Remove any on-load hiding styles */
	setTimeout(function() {
		$('.loading').removeClass('loading');
	},100);


	/* Check for iOS -- enable hovers if NOT iOS */

	// var isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

	// if (!isiOS)
	// 	$('body').addClass('no-touch');


	/* Search banner */

	var searchPath = $('.home').hasClass('isoffice') ? "" :
		($('.home').hasClass('commercial') ? "commercial" : "residential");

	$('.home .search-form').on('submit', function() {
		var searchTerm = $(this).find('.search-input-text').val(),
			buyorlease = $(this).find('.buylease').val(),
			suburbCount = $(this).find("#listing_suburb_id").val() != "" ? $(this).find("#listing_suburb_id").val().split(',').length : 0;

		if(suburbCount === 1 && buyorlease != 'sold'){
			var thisMethod = "buy";
			if( buyorlease == "lease" ){
				var thisMethod = "rent";
			}
			var searchSuburbName = sGlobs.search.selectedItem.SUBURB_NAME.replace(/ +/g, '-').toLowerCase();;
			var searchState = sGlobs.search.selectedItem.STATE;
			var searchPostcode = sGlobs.search.selectedItem.POSTCODE;

			var suburbAction = thisMethod + "-real-estate-in-" + searchSuburbName + "-" + searchState + "-" + searchPostcode;

			this.action = suburbAction;
		} else {
			var buyorleaseSet = buyorlease ?
					"/" + buyorlease : "/";

			var action = buyorlease == "sold" ? "sold" : searchPath + buyorleaseSet;
			this.action = action.indexOf("/") > -1 ? action : "/" + action;
		}
	})


	var petfriendly = $('[data-search="petfriendly"]').prop('checked') ? "pets" : "";
	var _rentalfeatures = $('#search-form').find('[name=rental_features]').val();
	if (!_rentalfeatures)
		$('#search-form').find('[name=rental_features]').val(petfriendly);

	$('[data-search]').on('click', function(e) {

		var _this = this,
			form = $('#search-form');

		if (!form.length)
			return;

		if ($(_this).is('a')) {
			e.preventDefault();
			$('[data-search]').removeClass('active');
			$(_this).addClass('active');
		}

		var type = $(_this).attr('data-search');

		/* Reset form vars */
		form.find('[name=listing_category]').val('residential');

		if (!$(this).parents('.js-noRoute').length)
			form.attr('action', "/results");

		if (type != "petfriendly")
			form.find('[name=listing_sale_method]').val('');

		var exec = {
			'residential': function() {
				form.find('[name=listing_category]').val('residential');
				},
			'commercial': function() {
				form.find('[name=listing_category]').val('commercial');
				},
			'rural': function() {
				form.find('[name=listing_category]').val('rural');
				},
			'holiday': function() {
				form.find('[name=listing_sale_method]').val('holiday');
				},
			'petfriendly': function() {
				var features = $(_this).prop('checked') ? "pets" : "";
				var _value = form.find('[name=rental_features]').val();
				if (!_value || _value == "pets")
					form.find('[name=rental_features]').val(features);
				},
			'leased': function() {
				form.attr('action', '/leased');
				},
			'sold': function() {
				form.attr('action', '/sold');
				},
		}

		if (typeof exec[type] == "function")
			exec[type]();
	});


	/* Navigation */

	$('.js-menu').on('click', function(e) {
		e.preventDefault();

		$('.hamburger').toggleClass('is-active');

		if ($('.hamburger').hasClass('is-active')) {
			$('.nav__main').stop().slideDown(1000, "easeOutQuint")
				.find('.js-parent').removeClass('active');
		}
		else
			closeParentNav();
	})

	$('.nav__main a.js-parent, nav a.js-parent').on('click', function(e) {
		e.preventDefault();

		$('.nav__main').slideDown(1000, "easeOutQuint");
		var targetTitle = $(this).attr('data-open');
		var target = $('.nav__main a[data-open="'+targetTitle+'"]');

		openParentNav(target, targetTitle);

	})

	function openParentNav(target, menutitle) {
		var submenu = target ?
			target.next('ol') : $();

		if (submenu.length) {
			$('.nav__main .js-parent').removeClass('active');
			target.addClass('active');
			$('.nav__main ol').stop().slideUp().promise().done(function() {
				submenu.slideDown();
			});
		}


		if (menutitle && navBanners && navBanners[menutitle])
			updateBanners(navBanners[menutitle])


		function updateBanners(content) {

			for (var i = 1; i <= 2; i++)
				if (content[i] && content[i].blurb)
					loadContent(i);


			function loadContent(num) {
				var bannerTarget = $('.js-navbanner-'+num),
					contentMap = {
						"h2": {label: 'blurb', func: 'text'},
						"p":  {label: 'body', func: 'text'},
						"a":  {label: 'image_href', func: 'updateHref'},
						"button":  {label: 'image_label', func: 'text'},
						".js-image": {label: 'image_url', func: 'updateImg'}
					};

				bannerTarget.fadeOut().promise().done(function() {

					for (var x in contentMap) {
						var map = contentMap[x];

						$(this).find(x)
							[map.func]( content[num][map.label] )
					}

					// Check external link
					var href = content[num].image_href;
					var targetWindow =  href && href.indexOf('http') != -1 && href.indexOf('_self') == -1 ?
						"_blank" : "";

					bannerTarget.find('a')
						.removeClass('pa-btn-step')
						.attr('target', targetWindow)
						.off(); /* remove any js events */

					$(this).fadeIn();
				});

			}

		}

	}

	/* openParentNav helpers */
	$.fn.updateImg = function(url) {
		$(this).css('background', 'url('+url+') center center / cover no-repeat');
	}

	$.fn.updateHref = function(href) {
		$(this).attr('href',href.replace('?_self',''));
	}


	$('.nav__main .icon-close').on('click', function() {
		closeParentNav();
	})

	function closeParentNav() {
		$('.nav__main ol').stop().slideUp();
		$('.nav__main').stop().slideUp(500);
	}


	/* More Options */

	var moreoption_Styles = {
		homepage: {
			target: '',
			on: "Less Options",
			off: "More Options",
			classon: "spin",
			classoff: ""
		}
	}

	$('.btn-more-options').on('click', function() {
		var options = $('.more-options-container');
		options.stop();

		var style = $(this).attr('data-style');
		var styleObj = moreoption_Styles[style] ?
			moreoption_Styles[style] : void 0;

		if (styleObj)
			styleObj.target = $(this);

		flexShowIt(options, "slide", styleObj);
	});

	/* Content switch */

	$('.switch').on('click', function() {

		if( $(this).hasClass('disabled'))
			return false;

		var container = $(this).attr('data-type'), //container
			switchtype = $(this).attr('data-switch'), //switch
			showOnly = $(this).attr('data-showonly'), //dont hide anything if set
			containerAll = $(this).parents('.category-selectors');

		$('.switch').removeClass('active');
		$('a[data-type='+container+']').addClass('active');

		var articles = $('.switch-category-results article');

		if (!showOnly)
			$('.switch-category').fadeOut().promise().done(function() { // using promise to avoid loading mapinitiliaze whilst still hidden
				loadtheNewStuff();
			})
		else { // show only, remove showmore button container
			$(this).parent().fadeOut(function() {
				loadtheNewStuff();
			});

		}

		function loadtheNewStuff() {
			var displacement = sGlobs.CSS.sidecolWidth ?
				sGlobs.CSS.sidecolWidth : "250px";

			switch(switchtype) {

				case 'map': /* listing page */
					sGlobs.search.mapActive = true;
					// setTimeout(function() { // timeout fixes no-load glitch when re-initializing new map data
					// 	initializeMap();
					// },500)

					// articles.each(function(i,e) {
					// 	if (i >= 6) $(e).hide();
					// })

					// if (sGlobs.search.total <= 12 && sGlobs.search.total > 6)
					// 	infiniteLoadButton(); // --searchformrealmark.ks--

					$('.switch-category-results').fadeIn();

					loadListings.getResults();
					break;

				case 'mapProperty': /* listing detail page */
					$('#map-canvas').show();
					var wideView = $('.map-secondary:visible').length;

					if (wideView) {
						if (sGlobs.search.mapExpand)
							return false;

						// Need to not displace map if a child site, since no sidebar
						if(!isChildOffice){
							$('.banner__secondary.cp__map').css('width','calc(100% - '+displacement+')');
						} else {
							$('.banner__secondary.cp__map').css('width','calc(100%)');
						}
						$('.banner__primary').css('width','0');
						triggerRefresh();
						sGlobs.search.mapExpand = true;

					}
					else {
						$('.switch-category-map').show();
						initializeMap();
					}

					break;

				case 'list':
					loadListings.getResults();

				default:
					var wideView = $('.map-secondary:visible').length;
					if (wideView && sGlobs.search.mapExpand) {
						var mainBannerWidth = parseInt(displacement.replace("px","")) * 2;
						if(!isChildOffice){
							$('.banner__secondary.cp__map').css('width',displacement);
							$('.banner__primary').css('width','calc(100% - '+(mainBannerWidth*2)+'px)');
						} else {
							$('.banner__secondary.cp__map').css('width','calc(0%)');
							$('.banner__primary').css('width','calc(100%)');
						}
						triggerRefresh();
						sGlobs.search.mapExpand = false;

					}

					sGlobs.search.mapActive = false;
					articles.show();
					break;

			}


			var target = $('.'+container);

			flexShowIt(target, "fadein")

		}

		var refreshTimeout;
		function triggerRefresh() {
			clearTimeout(refreshTimeout);
			refreshTimeout = setTimeout(function() {
				initializeMap();
			},1000)
		}

	})

});


/* image loader */

function loadImgs() {

	return $('.ld__imgjs').each(function() {

		var _this = $(this),
			imgurl = $(this).attr('data-img'),
			background = $(this).attr('data-bg'),
			container = $(this).find('.ld__imgcontainer'),
			now = $.now();

		var align = {
			vertical: $(this).attr('data-align-vertical') || "center",
			horizontal: $(this).attr('data-align-horizontal') || "center"
		}

		// avoid stacking -- quit if this attempt is within 200ms of last
		var attempt = _this.data('attempt');
		if ( !attempt || (attempt && attempt < (now-200)) )
			_this.data('attempt', now );
		else
			return;

    /* imgurl: removed for consistency on local where no images */
		if(container.length && background) {
			var loadImage = new Image;

			loadImage.onload = function(_this) {
				_this.removeClass('ld__imgjs')
					.find('.js__loader').remove();

				// Set a delay to avoid page load stutter
				setTimeout(function() {
            if (!container.children().length) {
            	var $img = $("<img />", {
            		src: imgurl,
            		alt: _this.data("address") || ""
            	}).css({
            		"object-fit": "cover",
            		"width": "100%",
            		"height": "100%",
            		"position": "absolute",
            	})
            	container
            		.hide()
            		.replaceWith($img)
            		.fadeIn("slow");
            } else {
            	container.hide();
            	container
            		.css({
            			'background': 'url(' + imgurl + ') center / cover no-repeat'
            		})
            		.fadeIn("slow");
            }
				},500)
			}(_this)

			loadImage.src = imgurl;
		}

	})

}



/*******************************
/** HELPERS */


/* onscreen checker */

$.fn.isOnScreen = function(){

    var win = $(window);

    var viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    var bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();

    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));

};

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}



/* text switch animation */
/* el must have .anim-popoutin class */

function switchText(text, target) {

	target
		.addClass('anim__out')

	setTimeout(function() {
		target
			.html(text)
			.removeClass('anim__out');
	},400)

}


/* Switch the site branding
	-- primary
	-- corporate
	*/

function switchBrand(to) {
	if (sGlobs.CSS && sGlobs.CSS[to])
		for (var key in sGlobs.CSS[to])
			document.documentElement.style.setProperty(key, sGlobs.CSS[to][key]);
}


/* flex show
	because jQuery has problems detecting a hidden component as flex */

function flexShowIt(target, style, textObj) {

	switch(style) {

		case "slide":
			if (target.is(':visible')) {
				target.slideUp(500, "swing");

				/* hide */
				if (textObj)
					textObj.target
						.text(textObj.off)
						.addClass(textObj.classoff)
						.removeClass(textObj.classon);
			}
			else {
				if (target.hasClass('flex')) {
					target
						.css('display','flex')
						.hide()
						.slideDown(500, "easeOutBounce");
				}
				else
					target.slideDown(500, "easeOutBounce");

				/* show */
				if (textObj)
					textObj.target
						.text(textObj.on)
						.addClass(textObj.classon)
						.removeClass(textObj.classoff);
			}

			break;

		case "fadein":
			if (target.hasClass('flex'))
				target
					.css("display", "flex")
					.hide()
					.fadeIn("slow");
			else
				target
					.fadeIn("slow");

			break;
	}
}
